// Import global styles
import '@/styles/global.css'; 

// Import React and other necessary modules
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import { Send, Loader2, CloudLightning, Zap } from 'lucide-react';

// Images array
const images = [
  "/static/images/DynamicCreation/1.webp",
  "/static/images/DynamicCreation/2.webp",
  "/static/images/DynamicCreation/3.webp",
  "/static/images/DynamicCreation/4.webp",
  "/static/images/DynamicCreation/5.webp",
  "/static/images/DynamicCreation/6.webp",
  "/static/images/DynamicCreation/7.webp",
  "/static/images/DynamicCreation/8.webp",
  "/static/images/DynamicCreation/9.webp",
];

const CrypticPage = () => {
  // State variables
  const [message, setMessage] = useState('');
  const fullMessage = 'Be anyone, anywhere in worlds waiting for you to define them.';
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  // Type out the message one character at a time
  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setMessage(fullMessage.slice(0, index));
      index++;
      if (index > fullMessage.length) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('Email', email);

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbxb5gErF0ci4YH2QxnKtfEf-zp1ZUUJ0qXikRgl4RyTu558V59u2hITqUJk7j-FxZQ/exec',
        {
          method: 'POST',
          body: formData,
        }
      );

      if (response.ok) {
        setIsSubmitted(true);
        setEmail('');
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }

    setIsSubmitting(false);
  };

  // Handle content loaded
  useEffect(() => {
    const handleLoad = () => {
      setIsContentLoaded(true);
    };

    // Wait for images to load
    const imagesToLoad = document.querySelectorAll('img');
    let imagesLoaded = 0;

    imagesToLoad.forEach((img) => {
      if (img.complete) {
        imagesLoaded++;
        if (imagesLoaded === imagesToLoad.length) {
          handleLoad();
        }
      } else {
        img.addEventListener('load', () => {
          imagesLoaded++;
          if (imagesLoaded === imagesToLoad.length) {
            handleLoad();
          }
        });
      }
    });

    // If there are no images, set content loaded immediately
    if (imagesToLoad.length === 0) {
      setIsContentLoaded(true);
    }
  }, []);

  return (
    <div className="min-h-screen w-screen bg-gray-900 text-white font-orbitron flex flex-col items-center overflow-hidden">
      {!isContentLoaded && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 z-50">
          <Loader2 className="animate-spin text-white" size={48} />
        </div>
      )}
      {/* Main Content */}
      <div className={`relative w-full flex-1 flex flex-col items-center overflow-y-auto overflow-x-hidden hide-scrollbar ${!isContentLoaded ? 'invisible' : 'visible'}`}>
        {/* Storm background */}
        <div className="storm absolute top-0 left-0 right-0 bottom-0 opacity-10 pointer-events-none z-0">
          {[...Array(20)].map((_, i) => (
            <CloudLightning
              key={i}
              size={48}
              className="absolute"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 10}s`,
                animation: 'float 10s infinite',
              }}
            />
          ))}
          {[...Array(15)].map((_, i) => (
            <Zap
              key={i}
              size={32}
              className="absolute"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 10}s`,
                animation: 'float 10s infinite',
              }}
            />
          ))}
        </div>

        {/* Content */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={isContentLoaded ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1 }}
          className="text-center relative z-10 w-full flex flex-col items-center px-4"
        >
          {/* Heading */}
          <div className="spanning-animated-tempest-container mb-8 mt-8">
            <h1
              className="text-fill text-[24vw] sm:text-[18vw] md:text-[12vw] lg:text-[10vw] font-bold leading-none whitespace-nowrap"
              style={{
                color: 'transparent',
                background: 'linear-gradient(90deg, #74e5dc, #377772, #74e5dc)',
                backgroundSize: '200% 100%',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                animation:
                  'moveSlideshow 5s linear infinite, pulse 2s ease-in-out infinite',
              }}
            >
              Tempest AI
            </h1>
          </div>

          {/* Subheading */}
          <h2 className="text-xl md:text-2xl mb-4 px-4">A storm is brewing. MK3 is coming...</h2>

          {/* Animated Images */}
          <div className="relative w-full max-w-5xl h-[200px] sm:h-[300px] md:h-[400px] mb-8 overflow-hidden">
            <motion.div
              className="flex absolute"
              animate={{ x: ['0%', '-50%'] }}
              transition={{
                x: {
                  duration: 40,
                  repeat: Infinity,
                  ease: 'linear',
                },
              }}
              style={{ width: '200%' }}
            >
              {[...images, ...images].map((src, index) => (
                <div
                  key={index}
                  className="w-[200px] sm:w-[250px] md:w-[300px] h-full mx-2 flex-shrink-0"
                >
                  <img
                    src={src}
                    alt={`Carousel image ${index + 1}`}
                    className="w-full h-full object-cover rounded-lg"
                    width="300"
                    height="400"
                  />
                </div>
              ))}
            </motion.div>
          </div>

          {/* Form or Submission Message */}
          <motion.div
            className="mt-8 w-full max-w-md relative z-10 px-4"
            initial={{ opacity: 0 }}
            animate={isContentLoaded ? { opacity: 1 } : {}}
            transition={{ delay: 0.5, duration: 1 }}
          >
            {!isSubmitted ? (
              <>
                <h3 className="text-2xl font-bold mb-4 text-center">
                  Join the waitlist for early access
                </h3>
                <form onSubmit={submitForm} className="space-y-4">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full px-4 py-2 rounded-md text-gray-800 focus:outline-none focus:ring-2 focus:ring-[#74e5dc]"
                  />
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-[#74e5dc] hover:bg-[#377772] text-gray-800 font-bold py-2 px-4 rounded-md transition-colors duration-300 flex items-center justify-center"
                  >
                    {isSubmitting ? (
                      <Loader2 className="animate-spin mr-2" />
                    ) : (
                      <Send className="mr-2" />
                    )}
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </form>
              </>
            ) : (
              <div className="text-center">
                <h3 className="text-2xl font-bold mb-4">
                  Check your email soon...
                </h3>
                <Loader2 className="animate-spin mx-auto mt-4" size={32} />
              </div>
            )}
          </motion.div>

          {/* Bottom Message */}
          <motion.p
            className="mt-8 text-sm text-gray-400 text-center relative z-10 px-4 mb-8"
            initial={{ opacity: 0 }}
            animate={isContentLoaded ? { opacity: 1 } : {}}
            transition={{ delay: 0.5, duration: 1 }}
          >
            {message}
          </motion.p>
        </motion.div>
      </div>
    </div>
  );
};

// Render the component
const root = document.getElementById('root');
if (root) {
  ReactDOM.render(<CrypticPage />, root);
} else {
  console.error("Element with id 'root' not found");
}

export default CrypticPage;
